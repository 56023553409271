<template>
  <div>
    <h1
      class="d-none"
    >KADIN | Kamar Dagang dan Industri Indonesia - (INDONESIAN CHAMBER OF COMMERCE AND INDUSTRY)</h1>

    <!-- <div
      class="index-hero d-lg-flex align-items-lg-end"
      style="background-image: url('/hero-karikatur.png')"
    > -->
    <!-- <div
      class="index-hero"
      style="background-image: url('/bg-peduli.png')"
    > -->
    <div
      class="index-hero vaksin"
      style="background-image: url('/BG+Virus.png')"
    >
      <b-container fluid class="padding-fluid">
        <div class="row align-items-lg-end pb-5">
          <b-col lg="7">
            <div class="quote d-none">
              <h6 class="hero-subline">KADIN INDONESIA</h6>
              <h3
                class="hero-tagline"
              >KADIN Indonesia adalah organisasi pengusaha Indonesia yang bergerak di bidang perekonomian dan didirikan pada 24 September 1968 & diatur dalam UU Nomor 1 tahun 1987 tentang Kamar Dagang dan Industri</h3>
            </div>
          </b-col>
          <b-col lg="5" offset-lg="0" md="8" offset-md="2">
            <div class="wrap-daftar d-none">
              <h5>Pendaftaran</h5>
              <h6>Ingin bergabung ? silahkan klik dibawah ini untuk menjadi anggota KADIN Indonesia.</h6>
              <b-link
                href="https://mms.kadin.id/#!/ab"
                target="_blank"
                class="btn btn-outline-warning front-page w-100 mb-3"
              >Anggota Biasa</b-link>
              <b-link
                href="https://mms.kadin.id/#!/alb"
                target="_blank"
                class="btn btn-warning front-page w-100"
              >Anggota Luar Biasa</b-link>
            </div>
            <img src="/logo-kadin-52th.png" class="img-fluid kadin-anniv d-none" alt="Logo Ulang Tahun Kadin ke 52 Tahun" />
            <img src="/Title.png" class="img-fluid mb-4" alt="Program Vaksinasi Gotong Royong" />
            <b-link class="d-inline-block" href="https://vaksin.kadin.id/" target="_blank">
              <img src="/Button.png" alt="" class="img-fluid"  />
            </b-link>
          </b-col>
        </div>

        <div class="row peduli d-none">
          <b-col md="6">
            <img src="/peduli.png" alt="" class="hashtag">
            <h1>Buat Orang Lain Bahagia</h1>
            <h5>Donasi kami gandakan.</h5>
            <b-link href="http://kadin.id/ayosalingpeduli" target="_blank">
              Mulai Donasi
              <img src="/lengkung.png" alt="">
            </b-link>
          </b-col>
        </div>

        <div class="scroll">
          <div class="mouse">
            <img src="/mouse.png" alt />
          </div>
          <span class="scroll-text">Scroll Kebawah</span> 
        </div>
      </b-container>

      <!-- <img src="/image-kumpul.png" class="kumpul"> -->
      <!-- <img src="/binar.png" class="binar"> -->
    </div>

    <!-- Berita Covid -->
    <!-- <div class="covid-short">
      <b-container fluid class="padding-fluid">
        <b-row class="align-items-center">
          <b-col md="4">
            <h4 class="headsubline">BERITA SEPUTAR COVID-19</h4>
            <h3 class="headline">Temukan info terbaru seputar Covid-19</h3>
          </b-col>
          <b-col md="8">
            <b-link 
              class="news-short-item" 
              v-for="news in newsCovidShort.data" 
              :key="news.id" 
              :style="{backgroundImage: 'url(' + base_api_url + news.thumbnail + ')'}"
              :to="localePath({name:'news-event-news-detail-id-slug',params:{id:news.id,slug:news.slug}})"
            >
              <div class="wrap">
                <span class="line"></span>
                <h4>{{ news.title }}</h4>
              </div>
            </b-link>
          </b-col>
        </b-row>
      </b-container>
    </div> -->

    <!-- <div class="covid-home-wrap d-none" id="beritacovid">
      <b-container fluid class="padding-fluid">
        <b-row>
          <b-col md="6" v-if="newscovid19 && newscovid19.length>0">
            <h4 class="title-covid mb-0">Berita Seputar covid-19</h4>
            <b-row>
              <b-col md="12">
                <div :class="[{'opened': toggleBerita},'wrap-overflow-berita']">
                  <a
                    :href="d.url"
                    target="_blank"
                    class="berita-wrap"
                    v-for="(d,index) in newscovid19"
                    :key="index"
                  >
                    <span>{{formatDate(d.published_at,'DD MMMM YYYY')}}</span>
                    <h6>{{d.title}}</h6>
                  </a>
                </div>
                <b-link
                  href="#"
                  @click="toggleBeritaCovid"
                  :class="[{'opened': toggleBerita},'toggle-wrap']"
                >
                  {{ (toggleBerita) ? "Tampilkan lebih sedikit" : "tampilkan lebih banyak" }}
                  <i
                    class="fa fa-angle-down"
                  ></i>
                </b-link>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="6">
            <h4 class="title-covid mt-md-0 mt-4">Panduan Penanggulangan Covid-19</h4>
            <h5 class="subtitle-covid">Kementrian Kesehatan RI</h5>
            <b-row>
              <b-col md="12">
                <div :class="[{'opened': toggleKemKes},'wrap-overflow-kemkes']">
                  <a
                    href="https://www.kemkes.go.id/resources/download/info-terkini/COVID-19%20dokumen%20resmi/1%20Protokol-Kesehatan-COVID-19.pdf"
                    target="_blank"
                    class="berita-wrap small"
                  >
                    <span class="image">
                      <img src="/book.svg" alt />
                    </span>
                    <h6>Protokol Kesehatan</h6>
                  </a>
                  <a
                    href="https://www.kemkes.go.id/article/view/20031600003/Protokol-Kesehatan-COVID-19.html"
                    target="_blank"
                    class="berita-wrap small"
                  >
                    <span class="image">
                      <img src="/book.svg" alt />
                    </span>
                    <h6>Protokol Kesehatan dalam Gambar</h6>
                  </a>
                  <a
                    href="https://www.kemkes.go.id/resources/download/info-terkini/COVID-19%20dokumen%20resmi/1c%20Protokol-Perbatasan-COVID-19.pdf"
                    target="_blank"
                    class="berita-wrap small"
                  >
                    <span class="image">
                      <img src="/book.svg" alt />
                    </span>
                    <h6>Protokol Perbatasan</h6>
                  </a>
                  <a
                    href="https://www.kemkes.go.id/resources/download/info-terkini/COVID-19%20dokumen%20resmi/2%20Protokol-Komunikasi-COVID-19-ver-10-Mar-2020.pdf"
                    target="_blank"
                    class="berita-wrap small"
                  >
                    <span class="image">
                      <img src="/book.svg" alt />
                    </span>
                    <h6>Protokol Komunikasi</h6>
                  </a>
                  <a
                    href="https://www.kemkes.go.id/resources/download/info-terkini/COVID-19%20dokumen%20resmi/4%20Protokol-Area-Institusi-Pendidikan-COVID-19.pdf"
                    target="_blank"
                    class="berita-wrap small"
                  >
                    <span class="image">
                      <img src="/book.svg" alt />
                    </span>
                    <h6>Protokol Area Pendidikan</h6>
                  </a>
                  <a
                    href="https://www.kemkes.go.id/resources/download/info-terkini/COVID-19%20dokumen%20resmi/5%20Protokol-Area-dan-Transportasi-Publik-COVID-19.pdf"
                    target="_blank"
                    class="berita-wrap small"
                  >
                    <span class="image">
                      <img src="/book.svg" alt />
                    </span>
                    <h6>Protokol Area Publik dan Transportasi</h6>
                  </a>
                </div>
                <b-link
                  href="#"
                  @click="toggleKemKesCovid"
                  :class="[{'opened': toggleKemKes},'toggle-wrap']"
                >
                  {{ (toggleKemKes) ? "Tampilkan lebih sedikit" : "tampilkan lebih banyak" }}
                  <i
                    class="fa fa-angle-down"
                  ></i>
                </b-link>
              </b-col>
            </b-row>
            <h5 class="subtitle-covid with-margin">Badan Nasional Penanggulangan Bencana</h5>
            <b-row>
              <b-col md="12">
                <div :class="[{'opened': toggleBNPB},'wrap-overflow-bnpb']">
                  <a
                    href="https://www.covid19.go.id/wp-content/uploads/2020/03/MateriKomunikasiRisikountukFasilitasPelayananKesehatan.pdf"
                    target="_blank"
                    class="berita-wrap small"
                  >
                    <span class="image">
                      <img src="/book.svg" alt />
                    </span>
                    <h6>Materi Komunikasi Risiko Covid-19 Untuk Fasilitas Pelayanan Kesehatan</h6>
                  </a>
                  <a
                    href="https://www.covid19.go.id/wp-content/uploads/2020/03/12.-Melakukan-Perjalanan.pdf"
                    target="_blank"
                    class="berita-wrap small"
                  >
                    <span class="image">
                      <img src="/book.svg" alt />
                    </span>
                    <h6>Panduan Virus Corona Saat Melakukan Perjalanan</h6>
                  </a>
                  <a
                    href="https://www.covid19.go.id/wp-content/uploads/2020/03/10.-Pengusaha-dan-Bisnis.pdf"
                    target="_blank"
                    class="berita-wrap small"
                  >
                    <span class="image">
                      <img src="/book.svg" alt />
                    </span>
                    <h6>Panduan Virus Corona bagi Pengusaha dan Bisnis</h6>
                  </a>
                  <a
                    href="https://www.covid19.go.id/wp-content/uploads/2020/03/08.-Tenaga-kesehatan0D.pdf"
                    target="_blank"
                    class="berita-wrap small"
                  >
                    <span class="image">
                      <img src="/book.svg" alt />
                    </span>
                    <h6>Panduan Virus Corona Bagi Tenaga Kesehatan</h6>
                  </a>
                  <a
                    href="https://www.covid19.go.id/wp-content/uploads/2020/03/09.-Bila-orang-sakit-2.pdf"
                    target="_blank"
                    class="berita-wrap small"
                  >
                    <span class="image">
                      <img src="/book.svg" alt />
                    </span>
                    <h6>Panduan Apa yang Perlu Dilakukan Saat Sakit</h6>
                  </a>
                  <a
                    href="https://www.covid19.go.id/wp-content/uploads/2020/03/07.-Tokoh-Agama-dan-Masyarakat-.pdf"
                    target="_blank"
                    class="berita-wrap small"
                  >
                    <span class="image">
                      <img src="/book.svg" alt />
                    </span>
                    <h6>Panduan Virus Corona Bagi Tokoh Agama dan Masyarakat</h6>
                  </a>
                  <a
                    href="https://www.covid19.go.id/wp-content/uploads/2020/03/07.-Tokoh-Agama-dan-Masyarakat-.pdf"
                    target="_blank"
                    class="berita-wrap small"
                  >
                    <span class="image">
                      <img src="/book.svg" alt />
                    </span>
                    <h6>Panduan Virus Corona Bagi Tokoh Agama dan Masyarakat</h6>
                  </a>
                  <a
                    href="https://www.covid19.go.id/wp-content/uploads/2020/03/05.-Teachers-and-Students.pdf"
                    target="_blank"
                    class="berita-wrap small"
                  >
                    <span class="image">
                      <img src="/book.svg" alt />
                    </span>
                    <h6>Panduan Virus Corona Untuk Guru</h6>
                  </a>
                  <a
                    href="https://www.covid19.go.id/wp-content/uploads/2020/03/06.-Pregnant-Woman.pdf"
                    target="_blank"
                    class="berita-wrap small"
                  >
                    <span class="image">
                      <img src="/book.svg" alt />
                    </span>
                    <h6>Panduan Virus Corona Untuk Ibu Hamil</h6>
                  </a>
                  <a
                    href="https://www.covid19.go.id/wp-content/uploads/2020/03/04.-Parents.pdf"
                    target="_blank"
                    class="berita-wrap small"
                  >
                    <span class="image">
                      <img src="/book.svg" alt />
                    </span>
                    <h6>Panduan Virus Corona Untuk Orang Tua</h6>
                  </a>
                  <a
                    href="https://www.covid19.go.id/wp-content/uploads/2020/03/02.-Social-Distancing.pdf"
                    target="_blank"
                    class="berita-wrap small"
                  >
                    <span class="image">
                      <img src="/book.svg" alt />
                    </span>
                    <h6>Mengurangi Kontak Antarwarga</h6>
                  </a>
                  <a
                    href="https://www.covid19.go.id/wp-content/uploads/2020/03/01.-Hand-Washing.pdf"
                    target="_blank"
                    class="berita-wrap small"
                  >
                    <span class="image">
                      <img src="/book.svg" alt />
                    </span>
                    <h6>Tips Mengurangi Risiko Tertular Virus Corona</h6>
                  </a>
                  <a
                    href="https://www.covid19.go.id/wp-content/uploads/2020/03/Protokol-Perbatasan-COVID-19.pdf"
                    target="_blank"
                    class="berita-wrap small"
                  >
                    <span class="image">
                      <img src="/book.svg" alt />
                    </span>
                    <h6>Protokol Penanganan Covid-19 di Wilayah Perbatasan Indonesia</h6>
                  </a>
                  <a
                    href="https://www.covid19.go.id/wp-content/uploads/2020/03/3-Hal-penting.jpg"
                    target="_blank"
                    class="berita-wrap small"
                  >
                    <span class="image">
                      <img src="/book.svg" alt />
                    </span>
                    <h6>3 Hal yang Harus Diketahui Seputar Covid-19</h6>
                  </a>
                  <a
                    href="https://www.covid19.go.id/wp-content/uploads/2020/03/3-Hal-penting.jpg"
                    target="_blank"
                    class="berita-wrap small"
                  >
                    <span class="image">
                      <img src="/book.svg" alt />
                    </span>
                    <h6>3 Hal yang Harus Diketahui Seputar Covid-19</h6>
                  </a>
                  <a
                    href="https://www.covid19.go.id/wp-content/uploads/2020/03/Protokol-Area-dan-Transportasi-Publik-COVID-19.pdf"
                    target="_blank"
                    class="berita-wrap small"
                  >
                    <span class="image">
                      <img src="/book.svg" alt />
                    </span>
                    <h6>Protokol Penanganan Covid-19 di Area Transportasi Publik</h6>
                  </a>
                  <a
                    href="https://www.covid19.go.id/wp-content/uploads/2020/03/Protokol-Kesehatan-COVID-19.pdf"
                    target="_blank"
                    class="berita-wrap small"
                  >
                    <span class="image">
                      <img src="/book.svg" alt />
                    </span>
                    <h6>Protokol Kesehatan Penanganan Covid-19</h6>
                  </a>
                  <a
                    href="https://www.covid19.go.id/wp-content/uploads/2020/03/Protokol-Area-Institusi-Pendidikan-COVID-19.pdf"
                    target="_blank"
                    class="berita-wrap small"
                  >
                    <span class="image">
                      <img src="/book.svg" alt />
                    </span>
                    <h6>Protokol Penanganan Covid-19 di Area Institusi Pendidikan</h6>
                  </a>
                  <a
                    href="https://www.covid19.go.id/wp-content/uploads/2020/03/Protokol-Komunikasi-COVID-19.pdf"
                    target="_blank"
                    class="berita-wrap small"
                  >
                    <span class="image">
                      <img src="/book.svg" alt />
                    </span>
                    <h6>Protokol Komunikasi Publik</h6>
                  </a>
                  <a
                    href="https://www.covid19.go.id/portfolio-items/cuci-tangan-pakai-sabun/"
                    target="_blank"
                    class="berita-wrap small"
                  >
                    <span class="image">
                      <img src="/book.svg" alt />
                    </span>
                    <h6>Cuci Tangan Pakai Sabun</h6>
                  </a>
                  <a
                    href="https://www.covid19.go.id/wp-content/uploads/2020/03/FLYER-GERMAS_COVID-19-v2.pdf"
                    target="_blank"
                    class="berita-wrap small"
                  >
                    <span class="image">
                      <img src="/book.svg" alt />
                    </span>
                    <h6>Gerakan Masyarakat Hidup Sehat</h6>
                  </a>
                  <a
                    href="http://www.covid19.go.id/wp-content/uploads/2020/03/Flyer-2020-etika-batuk.pdf"
                    target="_blank"
                    class="berita-wrap small"
                  >
                    <span class="image">
                      <img src="/book.svg" alt />
                    </span>
                    <h6>Etika Batuk yang Baik</h6>
                  </a>
                  <a
                    href="http://www.covid19.go.id/wp-content/uploads/2020/03/Flyer-2020-coronavirus-masyarakat.pdf"
                    target="_blank"
                    class="berita-wrap small"
                  >
                    <span class="image">
                      <img src="/book.svg" alt />
                    </span>
                    <h6>Tips Mengenali dan Mencegah Virus Corona</h6>
                  </a>
                  <a
                    href="http://www.covid19.go.id/wp-content/uploads/2020/03/Rev-03-Cara-Memakai-masker.pdf"
                    target="_blank"
                    class="berita-wrap small"
                  >
                    <span class="image">
                      <img src="/book.svg" alt />
                    </span>
                    <h6>Cara Menggunakan masker Yang Benar</h6>
                  </a>
                  <a
                    href="http://www.covid19.go.id/wp-content/uploads/2020/03/CERITA-SI-KORONA-Watiek-Ideo-dan-Luluk-N.pdf"
                    target="_blank"
                    class="berita-wrap small"
                  >
                    <span class="image">
                      <img src="/book.svg" alt />
                    </span>
                    <h6>Cerita Si Corona</h6>
                  </a>
                  <a
                    href="http://www.covid19.go.id/wp-content/uploads/2020/03/Cara-mencuci-tangan.jpeg"
                    target="_blank"
                    class="berita-wrap small"
                  >
                    <span class="image">
                      <img src="/book.svg" alt />
                    </span>
                    <h6>Selamatkan Nyawa Bersihkan Tangan Anda</h6>
                  </a>
                  <a
                    href="https://www.covid19.go.id/portfolio-items/pesan-bagi-petugas-kesehatan-tentang-covid-19/"
                    target="_blank"
                    class="berita-wrap small"
                  >
                    <span class="image">
                      <img src="/book.svg" alt />
                    </span>
                    <h6>Pesan Bagi Petugas Kesehatan Tentang Covid-19</h6>
                  </a>
                  <a
                    href="https://www.covid19.go.id/portfolio-items/tetap-sehat-saat-perjalanan-2/"
                    target="_blank"
                    class="berita-wrap small"
                  >
                    <span class="image">
                      <img src="/book.svg" alt />
                    </span>
                    <h6>Tips Tetap Sehat Saat Perjalanan</h6>
                  </a>
                  <a
                    href="https://www.covid19.go.id/portfolio-items/mengatasi-stress-selama-wabah-covid-19/"
                    target="_blank"
                    class="berita-wrap small"
                  >
                    <span class="image">
                      <img src="/book.svg" alt />
                    </span>
                    <h6>Mengatasi Stress Selama Wabah Covid-19</h6>
                  </a>
                  <a
                    href="https://www.covid19.go.id/portfolio-items/tetap-sehat-saat-berpergian/"
                    target="_blank"
                    class="berita-wrap small"
                  >
                    <span class="image">
                      <img src="/book.svg" alt />
                    </span>
                    <h6>Tips Tetap Sehat Saat Bepergian</h6>
                  </a>
                  <a
                    href="https://www.covid19.go.id/portfolio-items/check-sebelum-berpergian/"
                    target="_blank"
                    class="berita-wrap small"
                  >
                    <span class="image">
                      <img src="/book.svg" alt />
                    </span>
                    <h6>Tips Di Tempat Kerja: Check Sebelum Bepergian</h6>
                  </a>
                  <a
                    href="https://www.covid19.go.id/portfolio-items/mematuhi-larangan-setempat/"
                    target="_blank"
                    class="berita-wrap small"
                  >
                    <span class="image">
                      <img src="/book.svg" alt />
                    </span>
                    <h6>Tips Tempat Kerja mematuhi Larangan Setempat</h6>
                  </a>
                  <a
                    href="https://www.covid19.go.id/portfolio-items/5-info-penting/"
                    target="_blank"
                    class="berita-wrap small"
                  >
                    <span class="image">
                      <img src="/book.svg" alt />
                    </span>
                    <h6>Tips Mempersiapkan Tempat Kerja Aman dari Covid-19</h6>
                  </a>
                  <a
                    href="https://www.covid19.go.id/portfolio-items/bersihkan-semua-permukaan-dengan-disinfektan/"
                    target="_blank"
                    class="berita-wrap small"
                  >
                    <span class="image">
                      <img src="/book.svg" alt />
                    </span>
                    <h6>Bersihkan Semua Permukaan Dengan Disinfektan</h6>
                  </a>
                  <a
                    href="https://www.covid19.go.id/portfolio-items/sediakan-masker-untuk-mereka-yang-pilek-atau-batuk/"
                    target="_blank"
                    class="berita-wrap small"
                  >
                    <span class="image">
                      <img src="/book.svg" alt />
                    </span>
                    <h6>Tips Ditempat Kerja: Menjaga Kebersihan Pernapasan</h6>
                  </a>
                  <a
                    href="https://www.covid19.go.id/portfolio-items/testing-new-social-media-asset/"
                    target="_blank"
                    class="berita-wrap small"
                  >
                    <span class="image">
                      <img src="/book.svg" alt />
                    </span>
                    <h6>Penderita Batuk dan Demam Harus Istirahat Di Rumah</h6>
                  </a>
                  <a
                    href="https://www.covid19.go.id/portfolio-items/a3-poster-know-how-to-reduce-risk/"
                    target="_blank"
                    class="berita-wrap small"
                  >
                    <span class="image">
                      <img src="/book.svg" alt />
                    </span>
                    <h6>A3 Poster Know How to Reduce Risk</h6>
                  </a>
                </div>
                <b-link
                  href="#"
                  @click="toggleBNPBCovid"
                  :class="[{'opened': toggleBNPB},'toggle-wrap']"
                >
                  {{ (toggleBNPB) ? "Tampilkan lebih sedikit" : "tampilkan lebih banyak" }}
                  <i
                    class="fa fa-angle-down"
                  ></i>
                </b-link>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </div> -->

    <!-- E-Magazine -->
    <!-- <div class="magazine-home" v-if="dataMagazine">
      <b-container fluid class="padding-fluid">
        <b-row class="align-items-center">
          <b-col md="5" class="d-flex align-items-center justify-content-center">
            <template v-if="dataMagazine.data.length>0">
              <div class="mag-image" :style="{ backgroundImage: `url(${base_api_url}${dataMagazine.data[0].thumbnail})` }"></div>
              <div class="mag-image back" :style="{ backgroundImage: `url(${base_api_url}${dataMagazine.data[0].thumbnail})` }"></div>
            </template>
            <tempalte v-else>
              <div class="mag-image" style="background-color: #eee"></div>
              <div class="mag-image back" style="background-color: #eee"></div>
            </tempalte>
          </b-col>
          <b-col md="6">
            <span class="tag" v-if="dataMagazine.data.length>0">E-MAGAZINE KADIN – EDISI {{ dataMagazine.data[0].edition | edition }}</span>
            <span class="tag" v-else>E-MAGAZINE KADIN</span>
            <h4 class="title" v-if="dataMagazine.data.length>0">{{ dataMagazine.data[0].title }}</h4>
            <h4 class="title" v-else>title magazine</h4>
            <p v-if="dataMagazine.data.length>0">{{ dataMagazine.data[0].description }}</p>
            <p v-else>description magazine</p>
            <b-link to="/emagazine" class="btn btn-primary">BACA SELENGKAPNYA</b-link>
          </b-col>
        </b-row>
      </b-container>
    </div> -->

    <div class="new-news" v-if="recentNews.length>0">
      <b-container fluid class="padding-fluid">
        <div class="row">
          <b-col md="12">
            <b-link
              :to="localePath({name:'news-event-news-list-cat_slug',params:{cat_slug:recentNews[0].category_slug}})"
            >
              <h6 class="news-title">{{$t('messages.beritaterbaru')}}</h6>
            </b-link>
            <nuxt-link
              :to="localePath({name:'news-event'})"
              class="lihat"
            >{{$t('messages.lihatsemua')}}</nuxt-link>
          </b-col>
          <b-col lg="6" md="12">
            <nuxt-link
              :to="localePath({name:'news-event-news-detail-id-slug',params:{id:recentNews[0].id,slug:recentNews[0].slug}})"
              class="highlight-news"
              v-lazy:background-image.container="base_api_url+recentNews[0].thumbnail"
            >
              <span class="date">{{formatDate(recentNews[0].published_at)}}</span>
              <h4>{{recentNews[0].title}}</h4>
              <p>{{recentNews[0].short_description}}</p>
            </nuxt-link>
          </b-col>
          <b-col lg="6" md="12" v-if="recentNews.length>1">
            <div class="row">
              <template v-for="(data,index) in recentNews">
                <b-col md="6" v-if="index!=0" :key="data.id">
                  <nuxt-link
                    :to="localePath({name:'news-event-news-detail-id-slug',params:{id:data.id,slug:data.slug}})"
                    class="other-item"
                  >
                    <div class="wrap">
                      <span class="date">{{formatDate(data.published_at)}}</span>
                      <h4>{{data.title}}</h4>
                    </div>
                    <div
                      class="image"
                      v-lazy:background-image.container="base_api_url+data.thumbnail"
                    ></div>
                  </nuxt-link>
                </b-col>
              </template>
            </div>
          </b-col>
        </div>
      </b-container>
    </div>

    <!-- <div class="kadin-talk">
      <b-container fluid class="padding-fluid">
        <b-row>
          <b-col cols="12">
            <div class="ktalk-head d-none">
              <img v-lazy="require('~/static/kadintalks.png')" alt="Kadin" />
              <h2 class="head-placeholder">{{$t('pages.kadintalks')}}</h2>
            </div>

            <component
              :is="slickComp"
              ref="slickKtalk"
              :options="slickKtalkOptions"
              class="ktalk-slider"
            >
              <div class="ktalk-item" v-for="v in kadintalks" :key="v.id">
                <div class="row align-items-center">
                  <b-col md="6">
                    <nuxt-link
                      :to="localePath({name:'kadintalks-id-slug',params:{id:v.id,slug:v.slug}})"
                      class="video-play"
                      v-lazy:background-image.container="base_api_url+v.thumbnail"
                    >
                      <span>
                        <img v-lazy="require('~/static/play.png')" alt="Kadin" />
                      </span>
                      <img
                        v-lazy="require('~/static/kadintalks.png')"
                        class="img-ktalk"
                        alt="Kadin"
                      />
                    </nuxt-link>
                  </b-col>
                  <b-col md="6" class="pl-md-5 mb-md-5">
                    <h6 class="ktalk-subtitle">kadin talks</h6>
                    <h4 class="ktalk-title">{{v.title}}</h4>
                    <p
                      class="ktalk-desc"
                    >Ketua Umum Kadin Indonesia, Rosan P. Roeslani menyambut baik atas kunjungan Menteri Koordinator Bidang Perekonomian Airlangga Hartarto ke acara Kadin Talks sebagai wujud penguatan sinergi dan koordinasi antara pemerintah dan dunia usaha.</p>
                    <p
                      class="d-none"
                    >{{$t('messages.diterbitkan') + ' ' + formatDate(v.published_at)}}</p>
                    <nuxt-link
                      :to="localePath({name:'kadintalks-id-slug',params:{id:v.id,slug:v.slug}})"
                      class="ktalk-link"
                    >
                      Tonton Video
                      <img src="/underscore.png" alt />
                    </nuxt-link>
                  </b-col>
                </div>
              </div>
            </component>

            <div class="text-center d-none">
              <b-link
                :to="localePath({name:'kadintalks-list'})"
                class="more-video"
              >{{$t('messages.lihatvideokadintalks')}}</b-link>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div> -->

    <div class="event-home">
      <b-container fluid class="padding-fluid">
        <b-row>
          <b-col lg="3" md="4">
            <div class="event-head">
              <b-link
                class="float-none"
                :to="localePath({name:'news-event-event-list-cat_slug',params:{cat_slug:'event'}})"
              >
                <h6>{{$t('messages.event')}}</h6>
              </b-link>
              <h4>Temukan berbagai event terbaru Kadin Indonesia</h4>
              <nuxt-link
                v-if="events.length>0"
                class="d-none"
                :to="localePath({name:'news-event-event-list-cat_slug',params:{cat_slug: events.length>0 ? events[0].category_slug :'recent'}})"
              >
                {{$t('messages.lihatevents')}}
                <img
                  v-lazy="require('~/static/arrow-rblue.png')"
                  alt="Kadin"
                />
              </nuxt-link>
            </div>
          </b-col>
          <b-col lg="9" md="8">
            <component
              :is="slickComp"
              ref="slickEvent"
              :options="slickEventOptions"
              class="event-slider"
            >
              <div class="event-item" v-for="ev in events" :key="ev.id">
                <span
                  class="event-image"
                  v-lazy:background-image.container="base_api_url+ev.thumbnail"
                ></span>
                <nuxt-link
                  :to="localePath({name:'news-event-event-detail-id-slug',params:{id:ev.id,slug:ev.slug}})"
                  class="event-text"
                >
                  <h6>{{ formatEventStart(ev.start) + " - " + formatEventEnd(ev.end) }}</h6>
                  <h3>{{ev.title}}</h3>
                  <h6 class="event-end" v-if="eventEnded(ev.end)">Event sudah berakhir</h6>
                </nuxt-link>
              </div>
            </component>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <!-- not used -->
    <b-carousel
      id="carousel1"
      class="carousel-hero d-none"
      style="text-shadow: 1px 1px 2px #333;"
      controls
      indicators
      :interval="4000"
      img-width="1366"
      img-height="549"
      v-model="slide"
      @sliding-end="onSlideEnd"
    >
      <no-ssr>
        <b-carousel-slide v-for="arraySlide in arraySlides" :key="arraySlide.id">
          <img slot="img" v-lazy="arraySlide.image" :alt="arraySlide.caption" />
          <b-container>
            <div class="wrap">
              <h3 v-if="arraySlide.sub">{{ arraySlide.caption }}</h3>
              <p v-html="arraySlide.sub"></p>
              <b-link :href="arraySlide.link" v-if="arraySlides.link">
                {{$t('messages.selengkapnya')}}
                <img
                  v-lazy="require('~/static/arrow-rwhite.png')"
                  alt="Kadin"
                />
              </b-link>
            </div>
          </b-container>
        </b-carousel-slide>
      </no-ssr>
      <div class="next-title">
        <img v-lazy="require('~/static/icon-image.png')" alt="Kadin" />
        <p>{{ activeSlide.caption }}</p>
      </div>
    </b-carousel>

    <div class="news-home d-none">
      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <nuxt-link :to="localePath({name:'news-event'})" class="more">
              {{$t('messages.lihatberita')}}
              <img
                v-lazy="require('~/static/arrow-rblue.png')"
                alt="Kadin"
              />
            </nuxt-link>
          </b-col>
          <b-col>
            <b-tabs content-class="mt-3" end>
              <b-tab title="news" active @click="reInit('recentnews')">
                <template slot="title">
                  <span class="image-news">
                    <img v-lazy="require('~/static/news-g.png')" alt="Berita Kadin" class="gray" />
                    <img v-lazy="require('~/static/news-w.png')" alt="Berita Kadin" class="white" />
                  </span>
                  <h6>{{$t('messages.beritaterbaru')}}</h6>
                </template>
                <b-row>
                  <b-col lg="3" offset-lg="1" md="4" v-if="recentNews[0]">
                    <div class="news-feature">
                      <span class="cats">{{$t('messages.beritaterbaru')}}</span>
                      <h5>
                        <span v-html="recentNews[0].title"></span>
                      </h5>
                      <!-- <h6>{{recentNews[0].short_description.replace(/(<([^>]+)>)/ig,"")}}</h6> -->
                      <nuxt-link
                        v-if="!recentNews[0].url_external"
                        :to="localePath({name:'news-event-news-detail-id-slug',params:{id:recentNews[0].id,slug:recentNews[0].slug}})"
                      >
                        {{$t('messages.selengkapnya')}}
                        <img
                          v-lazy="require('~/static/arrow-rblue.png')"
                          alt="Kadin"
                        />
                      </nuxt-link>
                      <a v-if="recentNews[0].url_external" :href="recentNews[0].url_external">
                        {{$t('messages.selengkapnya')}}
                        <img
                          v-lazy="require('~/static/arrow-rblue.png')"
                          alt="Kadin"
                        />
                      </a>
                    </div>
                  </b-col>
                  <b-col lg="8" md="8" class="px-0">
                    <component
                      :is="slickComp"
                      ref="slick-recentnews"
                      :options="slickOptions"
                      class="news-repost"
                    >
                      <template v-for="(value,index) in recentNews">
                        <a
                          target="_blank"
                          v-if="index!=0 && value.url_external"
                          :href="value.url_external"
                          :key="'external'+value.id"
                          class="news-item pt-0"
                        >
                          <div
                            class="nr-bg"
                            v-lazy:background-image.container="value.thumbnail_external"
                          ></div>
                          <div class="nr-wrap">
                            <span class="line"></span>
                            <h6>
                              <span v-html="value.title"></span>
                            </h6>
                          </div>
                        </a>
                        <nuxt-link
                          :to="localePath({name:'news-event-news-detail-id-slug',params:{id:value.id,slug:value.slug}})"
                          :key="'internal'+value.id"
                          v-if="index!=0 && !value.url_external"
                        >
                          <div
                            class="nr-bg"
                            v-lazy:background-image.container="base_api_url+value.thumbnail"
                          ></div>
                          <div class="nr-wrap">
                            <span class="line"></span>
                            <h6>
                              <span v-html="value.title"></span>
                            </h6>
                          </div>
                        </nuxt-link>
                      </template>
                    </component>
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab
                v-for="cat in newsCategories"
                :key="cat.id"
                title="cat.name"
                @click="reInit('cat-'+cat.id)"
                :class="'cat-'+cat.id"
              >
                <template slot="title">
                  <span class="image-news">
                    <img v-lazy="require('~/static/house-g.png')" alt="Kadin" class="gray" />
                    <img v-lazy="require('~/static/house-w.png')" alt="Kadin" class="white" />
                  </span>
                  <h6>{{cat.name}}</h6>
                </template>
                <b-row>
                  <b-col
                    lg="3"
                    offset-lg="1"
                    md="4"
                    v-if="typeof news[cat.id]!='undefined' && typeof news[cat.id][0]!='undefined' "
                  >
                    <div class="news-feature">
                      <span class="cats">{{news[cat.id][0].category_name}}</span>
                      <h5>
                        <span v-html="news[cat.id][0].title"></span>
                      </h5>
                      <!-- <h6>{{recentNews[0].short_description.replace(/(<([^>]+)>)/ig,"")}}</h6> -->
                      <a :href="news[cat.id][0].url_external" v-if="news[cat.id][0].url_external">
                        {{$t('messages.selengkapnya')}}
                        <img
                          v-lazy="require('~/static/arrow-rblue.png')"
                          alt="Kadin"
                        />
                      </a>
                      <nuxt-link
                        v-if="!news[cat.id][0].url_external"
                        :to="localePath({name:'news-event-news-detail-id-slug',params:{id:news[cat.id][0].id,slug:news[cat.id][0].slug}})"
                      >
                        {{$t('messages.selengkapnya')}}
                        <img
                          v-lazy="require('~/static/arrow-rblue.png')"
                          alt="Kadin"
                        />
                      </nuxt-link>
                    </div>
                  </b-col>
                  <b-col lg="8" md="8" class="px-0">
                    <component
                      :is="slickComp"
                      :ref="'slick-cat-'+cat.id"
                      :options="slickOptions"
                      class="news-repost"
                    >
                      <template v-for="(value,index) in news[cat.id]">
                        <a
                          target="_blank"
                          v-if="index!=0 && value.url_external"
                          :href="value.url_external"
                          :key="'external'+value.id"
                          class="news-item pt-0"
                        >
                          <div
                            class="nr-bg"
                            v-lazy:background-image.container="value.thumbnail_external"
                          ></div>
                          <div class="nr-wrap">
                            <span class="line"></span>
                            <h6>
                              <span v-html="value.title"></span>
                            </h6>
                          </div>
                        </a>
                        <nuxt-link
                          :to="localePath({name:'news-event-news-detail-id-slug',params:{id:value.id,slug:value.slug}})"
                          :key="'internal'+value.id"
                          v-if="index!=0 && !value.url_external"
                        >
                          <div
                            class="nr-bg"
                            v-lazy:background-image.container="base_api_url+value.thumbnail"
                          ></div>
                          <div class="nr-wrap">
                            <span class="line"></span>
                            <h6>
                              <span v-html="value.title"></span>
                            </h6>
                          </div>
                        </nuxt-link>
                      </template>
                    </component>
                  </b-col>
                </b-row>
              </b-tab>
            </b-tabs>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <!-- not used end -->
  </div>
</template>

<script>
import moment from 'moment'
export default {
  layout: 'frontpage',
  async fetch({ app, store, params }) {
    const currentLocale = app.i18n.locale
    await store.dispatch('homeslider/getAllByLanguage', {
      page: 1,
      limit: 99999999,
      sortBy: 'order',
      sort: 'ASC',
      language_id: currentLocale,
      published: 1
    })
    await store.dispatch('kadintalks/getAllByLanguage', {
      page: 1,
      limit: 3,
      sortBy: 'published_at',
      sort: 'DESC',
      language_id: currentLocale,
      published: 1
    })
    let cat = await store.dispatch('categories/getAllByLanguage', {
      page: 1,
      limit: 99999999,
      sortBy: 'order',
      sort: 'ASC',
      category_type: 'berita',
      show_in_homepage: 1,
      language_id: currentLocale
    })
    await store.dispatch('homepage/getRecentEvents', {
      page: 1,
      limit: 5,
      sortBy: 'published_at',
      sort: 'DESC',
      language_id: currentLocale,
      published: 1
    })
    await store.dispatch('homepage/getRecentNews', {
      page: 1,
      limit: 5,
      sortBy: 'published_at',
      sort: 'DESC',
      language_id: currentLocale,
      category_type: 'berita',
      published: 1
    })
    await store.dispatch('newscovid19/getAll', {
      page: 1,
      limit: 9999999,
      sortBy: 'published_at',
      sort: 'DESC',
      published: 1
    })
    await store.dispatch('emagazine/getRecentMagazine', {
      offset: 0,
      limit: 1,
      sortBy: 'edition',
      sort: 'DESC'
    })
    for (const i in Object.values(cat.data.data)) {
      await store.dispatch('news/getByCategory', {
        page: 1,
        limit: 4,
        sortBy: 'published_at',
        sort: 'DESC',
        language_id: currentLocale,
        category_type: 'berita',
        category_id: cat.data.data[i].id,
        published: 1
      })
    }
    return await store.dispatch('news/getAllByLanguage', {
      page: 1,
      limit: 3,
      sortBy: 'published_at',
      sort: 'DESC',
      language_id: currentLocale,
      published: 1,
      category_slug: 'berita-covid-19'
    })
  },
  head() {
    return {
      meta: [
        {
          hid: 'title',
          name: 'title',
          content: this.settings.meta_title ? this.settings.meta_title : ''
        },
        {
          hid: 'description',
          name: 'description',
          content: this.settings.meta_description
            ? this.settings.meta_description
            : ''
        },
        {
          hid: 'og:image',
          name: 'og:image',
          property: 'og:image',
          content: this.base_url + '/logo-full.png'
        },
        {
          hid: 'og:url',
          name: 'og:url',
          property: 'og:url',
          content: this.base_url + this.$route.path
        }
      ]
    }
  },
  computed: {
    settings() {
      let data = this.$store.state.pageSettings
      let form = {}
      data.forEach(d => {
        form[d.key] = d.value
      })
      return form
    },
    arraySlides() {
      let list = this.$store.state.homeslider.list.data
      let slider = []
      list.forEach(value => {
        slider.push({
          id: value.id,
          image: process.env.API_BASE_URL + value.image,
          caption: value.title,
          sub: value.description,
          link: value.link
        })
      })
      return slider
    },
    kadintalks() {
      return this.$store.state.kadintalks.list.data
    },
    events() {
      return this.$store.state.homepage.recentEvents.data
    },
    newsCategories() {
      return this.$store.state.categories.list.data
    },
    news() {
      return this.$store.state.news.byCategory
    },
    recentNews() {
      return this.$store.state.homepage.recentNews.data
    },
    newscovid19() {
      return this.$store.state.newscovid19.list.data
    },
    newsCovidShort() {
      return this.$store.state.news.list
    },
    dataMagazine() {
      return this.$store.state.emagazine.recentMagazine
    }
  },
  data() {
    return {
      base_url: process.env.BASE_URL,
      base_api_url: process.env.API_BASE_URL,
      slide: 0,
      slickComp: '',
      isOpen: '',
      activeSlide: {},
      slickOptions: {
        slidesToShow: 4,
        arrows: false,
        autoplay: true,
        dots: false,
        infinite: false,
        responsive: [
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
              arrows: false,
              dots: false,
              infinite: false
            }
          }
        ]
      },
      slickEventOptions: {
        slidesToShow: 2,
        arrows: true,
        autoplay: true,
        dots: false,
        infinite: false,
        responsive: [
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 1
            }
          }
        ]
      },
      slickAktifitasOptions: {
        slidesToShow: 1,
        arrows: true,
        dots: true,
        autoplay: true
      },
      slickKtalkOptions: {
        slidesToShow: 1,
        arrows: false,
        autoplay: false,
        swipe: false,
        dots: false
      },
      toggleBerita: false,
      toggleKemKes: false,
      toggleBNPB: false
    }
  },
  created: function() {
    this.activeSlide =
      this.arraySlides && this.arraySlides.length > 0
        ? this.arraySlides[0]
        : { caption: '' }
  },
  components: {
    Slick: () => import('vue-slick')
  },
  mounted: function() {
    this.$nextTick(function() {
      this.slickComp = 'Slick'
    })
    if ($nuxt.$route.hash) {
      this.scrollToHash()
    }
  },
  methods: {
    next() {
      this.$refs.slick.next()
    },
    prev() {
      this.$refs.slick.prev()
    },
    reInit(id) {
      // Helpful if you have to deal with v-for to update dynamic lists
      //this.$refs.slick.reSlick();
      this.$nextTick(() => {
        setTimeout(() => {
          let ref = this.$refs['slick-' + id][0]
            ? this.$refs['slick-' + id][0]
            : this.$refs['slick-' + id]
          ref.reSlick()
        }, 1)
      })
    },
    onSlideEnd(slide) {
      const index = slide < this.arraySlides.length ? slide : 0
      this.activeSlide = this.arraySlides[index]
    },
    formatDate(value, format = 'dddd, DD MMMM YYYY') {
      if (value === null) {
        return ''
      } else {
        return moment(new Date(value))
          .locale(this.$i18n.locale)
          .format(format)
      }
    },
    formatEventStart(value) {
      if (value === null) {
        return ''
      } else {
        return moment(new Date(value))
          .locale(this.$i18n.locale)
          .format('DD')
      }
    },
    formatEventEnd(value) {
      if (value === null) {
        return ''
      } else {
        return moment(new Date(value))
          .locale(this.$i18n.locale)
          .format('DD MMM YYYY')
      }
    },
    toTitleCase(str) {
      return str.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
      })
    },
    eventEnded(tgl) {
      if (
        moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD').toDate() >
        moment(tgl, 'YYYY-MM-DD').toDate()
      ) {
        return true
      }
      return false
    },
    toggleBeritaCovid() {
      this.toggleBerita = !this.toggleBerita
    },
    toggleKemKesCovid() {
      this.toggleKemKes = !this.toggleKemKes
    },
    toggleBNPBCovid() {
      this.toggleBNPB = !this.toggleBNPB
    },
    scrollToHash() {
      var hash = $nuxt.$route.hash
      this.$nextTick(() => {
        this.$scrollTo(hash, 0, { offset: 0 })
      })
    }
  },
  filters: {
    edition: function(date) {
      return moment(date).format('MMMM YYYY')
    }
  }
}
</script>
